<template>
  <v-app-bar
    class="map-bar"
    app
    dense
    :height="48"
  >
    <UserAvatar v-if="userName">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="bg-ease"
          v-bind="attrs"
          dark
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-account-circle
          </v-icon>
          {{ userName }}
        </v-btn>
      </template>
    </UserAvatar>

    <DrawerSave
      v-if="editLayerNode"
      v-on="$listeners"
    >
      <template v-slot:activator="{ on, attrs, loading }">
        <v-btn
          v-bind="attrs"
          :disabled="!currentDrawType || isDrawing"
          :loading="loading"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </template>
    </DrawerSave>

    <DrawerNewFileDialog>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          :disabled="!currentDrawType || isDrawing"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-file-plus-outline
          </v-icon>
          {{ $t('save_as_new_file') }}
        </v-btn>
      </template>
    </DrawerNewFileDialog>

    <v-spacer />

    <TranslationMenu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          tile
          large
          height="100%"
          v-on="on"
        >
          <v-icon>
            mdi-translate
          </v-icon>
        </v-btn>
      </template>
    </TranslationMenu>

    <v-toolbar-title
      class="py-1"
      style="height: 100%;"
    >
      <v-btn
        :disabled="isDrawing"
        text
        tile
        x-large
        exact
        color="primary"
        height="100%"
        @click="backToMap"
      >
        <v-icon left>
          mdi-undo-variant
        </v-icon>
        {{ $t('back_to_map') }}
      </v-btn>
    </v-toolbar-title>

    <v-subheader class="text-subtitle-1">
      {{ $t('drawer_model') }}
    </v-subheader>
  </v-app-bar>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'
import DrawerNewFileDialog from '@/components/Map/Drawer/Dialogs/DrawerNewFileDialog.vue'
import DrawerSave from '@/components/Map/Drawer/DrawerSave.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DrawerBar',

  inject: {
    mapNavHeight: {
      from: 'mapNavHeight',
      default: 48
    }
  },

  components: {
    UserAvatar,
    TranslationMenu,
    DrawerNewFileDialog,
    DrawerSave
  },

  computed: {
    ...mapState({
      layerTree: state => state.map.layerTree,
      currentDrawType: state => state.map.drawer.currentDrawType,
      hasUpdate: state => state.map.drawer.hasUpdate,
      isDrawing: state => state.map.drawer.isDrawing
    }),
    ...mapGetters({
      userName: 'user/userName'
    }),

    editLayerNode: {
      get() {
        return this.$store.state.map.drawer.editLayerNode
      },
      set(newVal) {
        this.$store.commit('map/drawer/setState', { editLayerNode: newVal })
      }
    }
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    backToMap() {
      if (
        this.hasUpdate &&
        !window.confirm(this.$t('page.drawer.confirm_leave_page_when_drawing'))
      ) {
        return
      }

      if (this.editLayerNode) {
        this.layerTree.setMaplayerVisibility(this.editLayerNode)
        this.editLayerNode = null
      }

      this.$router.push({
        name: 'Map',
        params: this.$route.params,
        query: this.$route.query
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.map-bar.v-app-bar.v-toolbar.v-sheet {
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.79);

  ::v-deep .v-toolbar__content {
    padding-right: 0;
  }
}
</style>
